import { createStore } from "vuex";
import ApiService from "../../src/service/ApiService";
const apiService = new ApiService;
export default createStore({

    state: {
        didNumber: {
            addNewDIDNumberPopUp: false,
            didNumberCallApiStatus: false,
            updateDIDNumberPopUp: false,
            updateDIDNumberPopUpData:null
        },
        adminId: null,
        languageList: null,
        // updateDIDNumberPopUpData: null,
    },

    mutations: {
        toggleAddNewDIDNumberPopUp(state) {
            state.didNumber.addNewDIDNumberPopUp = !state.didNumber.addNewDIDNumberPopUp;
        },
        toggleUpdateDIDNumberPopUp(state, payload) {

            state.didNumber.updateDIDNumberPopUpData = payload;
            state.didNumber.updateDIDNumberPopUp = !state.didNumber.updateDIDNumberPopUp;
        },



        didNumberCallApiStatus(state) {
            state.didNumber.didNumberCallApiStatus = !state.didNumber.didNumberCallApiStatus;
        },
        updateHostRedirectStatus(state, payload) {
            state.adminId = payload;
        },
        getLanguages(state, data) {
            state.languageList = data.data;
        },
    },

    actions: {
        toggleAddNewDIDNumberPopUp(context) {
            context.commit("toggleAddNewDIDNumberPopUp");
        },
        toggleUpdateDIDNumberPopUp(context, payload) {
            context.commit('toggleUpdateDIDNumberPopUp', payload);
        },
        // toggleUpdateDIDNumberPopUp({ commit }, payload) {
        //     commit("toggleUpdateDIDNumberPopUp", payload);
        // },
        didNumberCallApiStatus(context) {
            context.commit("didNumberCallApiStatus");
        },
        updateHostRedirectStatus(context, payload) {
            context.commit("updateHostRedirectStatus", payload);
        },
        async getLanguages(context) {
            return await apiService.getLanguages().then((data) => {
                if (data.status == 200) {
                    context.commit("getLanguages", data)
                }
                return data
            });
        },
    }
});
