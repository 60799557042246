<template>
    <App :topbarTheme="topbarTheme" :layoutMode="layoutMode" :menuTheme="menuTheme" @menu-theme="onMenuTheme" @topbar-theme="onTopbarThemeChange" @layout-mode-change="onLayoutChange" />
</template>
<script>
import App from './App.vue';
export default {
  data() {
    return {
      theme: 'blue',
      layoutMode: 'light',
      topbarTheme: 'blue',
      menuTheme: 'light',
    };
  },
  methods: {
    onLayoutChange(layout) {
      this.layoutMode = layout;
      this.$appState.layoutMode = layout;
      this.menuTheme = layout;

      if (layout === 'dark') {
        this.topbarTheme = 'dark';
      } else {
        this.topbarTheme = 'blue';
      }
    },
    onTopbarThemeChange(theme) {
      this.topbarTheme = theme.name;
    },
    onMenuTheme(menuTheme) {
      this.menuTheme = menuTheme.name;
    },
  },
  components: {
    'App': App,
  },
};
</script>

<style scoped>
</style>
