import axios from 'axios';
import router from "@/router";

// type : 0= (common apis) 1 =(user apis)

export default class ApiService {
  StorageObjUserRef = localStorage.user_reference;
  StorageObjAdmin = localStorage.user ? localStorage.user : '';
  StorageObjMasterBucket = localStorage.masterBucketName ? localStorage.masterBucketName : '';
  admin = this.StorageObjAdmin.user_id ? this.StorageObjAdmin.user_id : '';
  user = localStorage.user;

  HeaderParam = {
    "Access-Control-Allow-Origin": "*",
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'content-type': 'application/json',
    'Accept': 'application/json',
  };

  AuthenticatedUserHeader = {
    "Access-Control-Allow-Origin": "*",
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': "Bearer " + this.StorageObjUserRef,
    user: this.user
  };

  commonapiall = (url, params, method, type, item) => {
    return axios(url, {
      baseURL: location.origin,
      method: method,
      headers: params,
      data: item,
    })
      .then(response => {
        if (response.data.status == 403) {
          this.userlogout();
        }
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  login(item) {
    let url = 'api/login';
    return this.commonapiall(url, this.HeaderParam, 'POST', 0, item);
  }

  userlogout = (item) => {
    const URL = 'api/userlogout';
    return axios(URL, {
      method: 'POST',
      baseURL: location.origin,
      headers: this.AuthenticatedUserHeader,
      data: item,
    })
      .then(response => {
        localStorage.clear();
        router.push({ path: '/login' });
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  }

  getAdminUsersList(item) {
    let url = 'api/u/getAdminUsersList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getStates(item) {
    let url = 'api/common/getStates';
    return this.commonapiall(url, this.HeaderParam, 'POST', 0, item);
  }

  getCities(item) {
    let url = 'api/common/getCities';
    return this.commonapiall(url, this.HeaderParam, 'POST', 0, item);
  }

  createUpdateAdminUser(item) {
    let url = 'api/u/createAdminUserFirstStep';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAdminUserDetails(item) {
    let url = 'api/u/getAdminUserDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  updateAdminUserconfig(item) {
    let url = 'api/u/updateAdminUserconfig';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getMasterPlans(item) {
    let url = 'api/common/getMasterPlans';
    return this.commonapiall(url, this.HeaderParam, 'POST', 0, item);
  }

  getExistingPlanComponents(item) {
    let url = 'api/u/getExistingPlanComponents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getPlanComponents(item) {
    let url = 'api/u/getPlanComponents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  doActionClientPlanComponentUpdate(item) {
    let url = 'api/u/doActionClientPlanComponentUpdate';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  assignPlanToclient(item) {
    let url = 'api/u/assignPlanToclient';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getRequestForPlanChange(item) {
    let url = 'api/u/getRequestForPlanChange';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAdminUsersOnly(item) {
    let url = 'api/u/getAdminUsersOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  createUpdateAdminUserRole(item) {
    let url = 'api/u/createUpdateAdminUserRole';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  removeAssignedRoles(item) {
    let url = 'api/u/removeAssignedRoles';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAllAdminUserDetails(item) {
    let url = 'api/u/getAllAdminUserDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAdminDocumentDetails(item) {
    let url = 'api/u/getAdminDocumentDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addUpdateAdminDocuments(payload) {
    let url = 'api/u/addUpdateAdminDocuments';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getAdminListOnly(item) {
    let url = 'api/u/getAdminListOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getHostListOnly(item) {
    let url = 'api/u/getHostListOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getHostUserDetails(item) {
    let url = 'api/u/getHostUserDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getHostBranchesOnly(item) {
    let url = 'api/common/getHostBranchesOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getHostBranchesUserDetails(item) {
    let url = 'api/u/getHostBranchesUserDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAdminFund(item) {
    let url = 'api/u/getClientFund';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getBillingCompany(item) {
    let url = 'api/common/getBillingCompany';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addAdminFund(item) {
    let url = 'api/u/addClientFund';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAdminDebitDetails(item) {
    let url = 'api/u/getClientDebitDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getTaxInvoices(item) {
    let url = 'api/u/getTaxInvoices';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getTaxInvoiceComponent(item) {
    let url = 'api/u/getTaxInvoiceComponent';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getPaymentReceipts(item) {
    let url = 'api/u/getPaymentReceipts';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  regeneratePaymentReceipt(item) {
    let url = 'api/u/regeneratePaymentReceipt';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAllPlans(item) {
    let url = 'api/u/getAllPlans';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addUpdatePlan(item) {
    let url = 'api/u/addUpdatePlan';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getPlanDetails(item) {
    let url = 'api/u/getPlanDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getComponentName(item) {
    let url = 'api/common/getComponentName';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  createPlanConfig(item) {
    let url = 'api/u/createPlanConfig';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  updatePlanConfig(item) {
    let url = 'api/u/updatePlanConfig';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getLiveBalance(item) {
    let url = 'api/u/getLiveBalance';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getComponentList(item) {
    let url = 'api/common/getComponentList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addComponentDebitAction(item) {
    let url = 'api/u/addComponentDebitAction';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getClientServiceSettings(item) {
    let url = 'api/u/getClientServiceSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addClientServiceSettings(item) {
    let url = 'api/u/addClientServiceSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getTemplateActions(item) {
    let url = 'api/u/getTemplateActions';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getWhatsAppTemplateSettings(item) {
    let url = 'api/u/getWhatsAppTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAllTemplateDescriptions(item) {
    let url = 'api/u/getAllTemplateDescriptions';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getchatbotList(item) {
    let url = 'api/common/getchatbotList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getTitles(item) {
    let url = 'api/common/getTitles';
    return this.commonapiall(url, this.HeaderParam, 'POST', 0, item);
  }

  getSuperAdminUserList(item) {
    let url = 'api/u/getSuperAdminUserList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  createUpdateSuperAdminUser(item) {
    let url = 'api/u/createUpdateSuperAdminUser';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getDIDNumberByStatus(item) {
    let url = 'api/u/getDIDNumberByStatus';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAllDefaultTunes(item) {
    let url = 'api/u/getAllDefaultTunes';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  createUpdateAllDefaultTunes(item) {
    let url = 'api/u/createUpdateAllDefaultTunes';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  isAlreadyPresentTemplateDescriptionId(item) {
    let url = 'api/u/isAlreadyPresentTemplateDescriptionId';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  createUpdateTemplateDescription(item) {
    let url = 'api/u/createUpdateTemplateDescription';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addDIDNumber(item) {
    let url = 'api/u/addDIDNumber';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  updateDIDNumber(item) {
    let url = 'api/u/updateDIDNumber';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAllTemplateOnly(item) {
    let url = 'api/common/getAllTemplateOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAdminTemplateActionsOnly(item) {
    let url = 'api/common/getAdminTemplateActionsOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getTemplateEvents(item) {
    let url = 'api/u/getTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addUpdateTemplateEvents(item) {
    let url = 'api/u/addUpdateTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getCurrentTemplateEvents(item) {
    let url = 'api/u/getCurrentTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getCurrentWPTemplateEvents(item) {
    let url = 'api/u/getCurrentWPTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getCurrentEmailTemplateEvents(item) {
    let url = 'api/u/getCurrentEmailTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getCurrentRCSTemplateEvents(item) {
    let url = 'api/u/getCurrentRCSTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getTemplateDescriptions(item) {
    let url = 'api/u/getTemplateDescriptions';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  isAlreadyPresentTemplateName(item) {
    let url = 'api/u/isAlreadyPresentTemplateName';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  copywhatsAppSettingToOtherClient(item) {
    let url = 'api/u/copywhatsAppSettingToOtherClient';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addUpdateClientwhatsAppTemplates(item) {
    let url = 'api/u/addUpdateClientwhatsAppTemplates';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getRCSTemplateSettings(item) {
    let url = 'api/u/getRCSTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addUpdateEventWhatsAppTemplateSettings(item) {
    let url = 'api/u/addUpdateEventWhatsAppTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addUpdateEventRCSTemplateSettings(item) {
    let url = 'api/u/addUpdateEventRCSTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addUpdateEventEmailTemplateSettings(item) {
    let url = 'api/u/addUpdateEventEmailTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAllRCSTemplateOnly(item) {
    let url = 'api/common/getAllRCSTemplateOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  isAlreadyPresentRCSTemplateName(item) {
    let url = 'api/u/isAlreadyPresentRCSTemplateName';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addUpdateClientRCSTemplates(item) {
    let url = 'api/u/addUpdateClientRCSTemplates';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  getHostDetails(item) {
    let url = 'api/u/getHostDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  getHostAllDetails(item) {
    let url = 'api/u/getHostAllDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  getHostBranchesDetails(item) {
    let url = "api/u/getHostBranchesDetails";
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  copyRCSSettingToOtherClient(item) {
    let url = 'api/u/copyRCSSettingToOtherClient';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getClientsAccountStatementDetails(item) {
    let url = 'api/u/getClientsAccountStatementDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  getClientsTaxInvoicesDetails(item) {
    let url = 'api/u/getClientsTaxInvoicesDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  getClientsPaymentReceiptDetails(item) {
    let url = 'api/u/getClientsPaymentReceiptDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAllVisitorsDetails(item) {
    let url = 'api/u/getAllVisitorsDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAllVisitorsDetailsByHost(item) {
    let url = 'api/u/getAllVisitorsDetailsByHost';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAllVisitorsDetailsByBranches(item) {
    let url = 'api/u/getAllVisitorsDetailsByBranches';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getCurrentSMSTemplateEvents(item) {
    let url = 'api/u/getCurrentSMSTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  addUpdateEventSMSTemplateSettings(item) {
    let url = 'api/u/addUpdateEventSMSTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  updateAdminUserCriticalPermissions(item) {
    let url = 'api/u/updateAdminUserCriticalPermissions';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAdminUserCriticalPermissions(item) {
    let url = 'api/u/getAdminUserCriticalPermissions';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getTicketsByStatus(item) {
    let url = 'api/u/getTicketsByStatus';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getTicketsMessage(item) {
    let url = 'api/u/getTicketMessages';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  assignTicket(payload) {
    let url = 'api/u/assignTicket';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getMyAndTeamAssignTicket(payload) {
    let url = 'api/u/getMyAssignAndTeamTicketList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  chat(payload) {
    let url = 'api/u/chat';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  closeTicket(payload) {
    let url = 'api/u/closeTicket';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  triggercalltomeranetaclients(payload) {
    let url = 'api/u/triggercalltomeranetaclients';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getAreaList(payload) {
    let url = 'api/common/getAreaList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getAdminUserRoles(payload) {
    let url = 'api/common/getAdminUserRoles';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  checkUserMobileisAlreadyPresent(payload) {
    let url = 'api/u/checkUserMobileisAlreadyPresent';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  createUpdateAdminUsers(payload) {
    let url = 'api/u/createUpdateAdminUsers';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getAllAdminUserRoleDetails(item) {
    let url = 'api/u/getAllAdminUserRoleDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getAdminUsersNotHavingRoles(item) {
    let url = 'api/common/getAdminUsersNotHavingRoles';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getActiveUsers(item) {
    let url = 'api/common/getActiveSuperAdminUsers';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getLanguages(item) {
    let url = 'api/common/getLanguages';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getAdminListOnlyExceptGivenId(payload) {
    let url = 'api/common/getAdminListOnlyExceptGivenId';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  copyTemplateActionToOtherAdmin(payload) {
    let url = 'api/u/copyTemplateActionToOtherAdmin';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getCompaniesList(payload) {
    let url = 'api/u/getCompaniesList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  createUpdateCompanies(payload) {
    let url = 'api/u/createUpdateCompanies';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getcompanyListOnly(payload) {
    let url = 'api/common/getcompanyListOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getCompaniesSenderIdList(payload) {
    let url = 'api/u/getCompaniesSenderIdList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  createUpdateCompaniesSenderId(payload) {
    let url = 'api/u/createUpdateCompaniesSenderId';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  // -----------------Super Admin Template Setting Related End Points Start-----------

  getSuperAdminTemplateActions(payload) {
    let url = 'api/u/getSuperAdminTemplateActions';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getSuperAdminTemplateActionsOnly(payload) {
    let url = 'api/common/getSuperAdminTemplateActionsOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getSuperAdminTemplateEvents(payload) {
    let url = 'api/u/getSuperAdminTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  addUpdateSuperAdminTemplateEvents(payload) {
    let url = 'api/u/addUpdateSuperAdminTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getCurrentSuperAdminTemplateEvents(payload) {
    let url = 'api/u/getCurrentSuperAdminTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getCurrentSuperAdminWPTemplateEvents(payload) {
    let url = 'api/u/getCurrentSuperAdminWPTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getCurrentSuperAdminEmailTemplateEvents(payload) {
    let url = 'api/u/getCurrentSuperAdminEmailTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getCurrentSuperAdminRCSTemplateEvents(payload) {
    let url = 'api/u/getCurrentSuperAdminRCSTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  addUpdateSuperAdminEventWhatsAppTemplateSettings(payload) {
    let url = 'api/u/addUpdateSuperAdminEventWhatsAppTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  addUpdateSuperAdminEventEmailTemplateSettings(payload) {
    let url = 'api/u/addUpdateSuperAdminEventEmailTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateSuperAdminEventRCSTemplateSettings(payload) {
    let url = 'api/u/addUpdateSuperAdminEventRCSTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getCurrentSuperAdminSMSTemplateEvents(payload) {
    let url = 'api/u/getCurrentSuperAdminSMSTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  addUpdateSuperAdminEventSMSTemplateSettings(payload) {
    let url = 'api/u/addUpdateSuperAdminEventSMSTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getAllWebBotTemplateOnly(payload) {
    let url = 'api/u/getAllWebBotTemplateOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getWebBotTemplateSettings(payload) {
    let url = 'api/u/getWebBotTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  isAlreadyPresentWebBotTemplateName(payload) {
    let url = 'api/u/isAlreadyPresentWebBotTemplateName';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  addUpdateClientWebBotTemplates(payload) {
    let url = 'api/u/addUpdateClientWebBotTemplates';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getSuperAdminWhatsAppTemplateEventsOnly(payload) {
    let url = 'api/common/getSuperAdminWhatsAppTemplateEventsOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  getSuperAdminRCSTemplateEventsOnly(payload) {
    let url = 'api/common/getSuperAdminRCSTemplateEventsOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  // -----------------Super Admin Template Setting Related End Points End-----------

  // -----------------Admin Template Setting Related start Points End-----------

  getAdminTemplateActions(payload) {
    let url = 'api/u/getAdminTemplateActions';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentAdminTemplateEvents(payload) {
    let url = "api/u/getCurrentAdminTemplateEvents";
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCompaniesSenderIdOnly(payload){
    let url = "api/common/getCompaniesSenderIdOnly";
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getDashboardLogoUploadDetails(payload){
    let url = "api/u/getDashboardLogoUploadDetails";
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateDashboardLogos(payload){
    let url = "api/u/createUpdateDashboardLogos";
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

}