import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () =>
            import('./pages/Login.vue'),
        meta: {
            plainLayout: true
        },
    },
    {
        path: '/login',
        name: 'login',
        exact: true,
        component: () =>
            import('./pages/Login.vue'),
        meta: {
            plainLayout: true,
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        exact: true,
        component: () =>
            import('./components/Dashboard.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ label: 'Dashboard' }],
        },
    },
    {
        path: '/:notFound',
        name: 'not-found',
        exact: true,
        component: () =>
            import('./pages/NotFound.vue'),
        meta: {
            plainLayout: true,
        },
    },
    {
        path: '/adminlist',
        name: 'listclient',
        exact: true,
        component: () =>
            import('./components/AdminList.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ label: 'Admin' }],
        },
    },
    {
        path: '/admin/dashboard/:adminId?',
        name: 'admindashboard',
        exact: true,
        component: () =>
            import('./components/admintabs/AdminDashboard.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Admin', label: 'Dashboard' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/admintinfo',
        name: 'admininfo',
        exact: true,
        component: () =>
            import('./components/admintabs/AdminInfoTab.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Admin Info' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/adminconfig',
        name: 'adminconfig',
        exact: true,
        component: () =>
            import('./components/admintabs/AdminConfigTab.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Admin Config' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/adminplan',
        name: 'adminplan',
        exact: true,
        component: () =>
            import('./components/admintabs/AdminPlanTab.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Admin', label: 'Admin Plan' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/addfund',
        name: 'adminaddfund',
        exact: true,
        component: () =>
            import('./components/admintabs/AddFundTab.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Admin', label: 'Add Fund' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/adminrole',
        name: 'adminrole',
        exact: true,
        component: () =>
            import('./components/admintabs/AdminRole.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Admin Role' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/admindocument',
        name: 'admindocument',
        exact: true,
        component: () =>
            import('./components/admintabs/AdminDocument.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Admin Document', }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/service-setting',
        name: 'service-setting',
        exact: true,
        component: () =>
            import('./components/admintabs/ServiceSetting.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Service Setting' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/add-debit',
        name: 'add-debit',
        exact: true,
        component: () =>
            import('./components/admintabs/AddDebit.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Dashboard', label: 'Add Debit' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/template-actions',
        name: 'template-actions',
        exact: true,
        component: () =>
            import('./components/admintabs/TemplateActions.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Admin', label: 'Template Actions' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/whatsapp-template-settings',
        name: 'whatsapp-template-settings',
        exact: true,
        component: () =>
            import('./components/admintabs/WhatsAppTemplateSettings.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Admin', label: 'WhatsApp Template Settings' }],
        },
    },
    {
        path: '/adminemployee',
        name: 'listadminemployee',
        exact: true,
        component: () =>
            import('./components/AdminEmployee.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Admin Users', label: 'List Admin Users' }],
        },
    },
    {
        path: '/billing/plan',
        name: 'billingplan',
        exact: true,
        component: () =>
            import('./components/BillingPlan.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Plan' }],
        },
    },
    {
        path: '/billing/clientPayment',
        name: 'clientpayment',
        exact: true,
        component: () =>
            import('./components/ClientPayment.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Client Payment' }],
        },
    },
    {
        path: '/billing/plan/component/:planrowId?',
        name: 'billingplancomponent',
        exact: true,
        component: () =>
            import('./components/billing/PlanComponent.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Components' }],
        },
    },
    {
        path: '/billing/taxinvoice',
        name: 'billinginvoice',
        exact: true,
        component: () =>
            import('./components/TaxInvoice.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Tax Invoice' }],
        },
    },
    {
        path: '/billing/receipt',
        name: 'billingreceipt',
        exact: true,
        component: () =>
            import('./components/PaymentReceipt.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Payment Receipt' }],
        },
    },
    {
        path: '/billing/adminbalance',
        name: 'clientbalance',
        exact: true,
        component: () =>
            import('./components/LiveBalance.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Billing', label: 'Admin Live Balance' }],
        },
    },
    {
        path: '/users',
        name: 'listusers',
        exact: true,
        component: () =>
            import('./components/Users.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Users', label: 'List Users' }],
        },
    },
    {
        path: '/D_I_D/Numbers/free-numbers',
        name: 'freeNumbers',
        exact: true,
        component: () =>
            import('./components/cloudtelephony/freeDIDNumbers.vue'),
        meta: {
            requiresAuth: false,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Cloud Telephony', label: 'Free Numbers' }],
        },
    },
    {
        path: '/D_I_D/Numbers/admin-numbers',
        name: 'adminNumbers',
        exact: true,
        component: () =>
            import('./components/cloudtelephony/adminDIDNumbers.vue'),
        meta: {
            requiresAuth: false,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Cloud Telephony', label: 'Admin Numbers' }],
        },
    },
    {
        path: '/D_I_D/Numbers/notice-period',
        name: 'underNoticePeriodNumbers',
        exact: true,
        component: () =>
            import('./components/cloudtelephony/underNoticePeriodNumbers.vue'),
        meta: {
            requiresAuth: false,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Cloud Telephony', label: 'Under Notice Period Numbers' }],
        },
    },
    {
        path: '/D_I_D/Numbers/submitted-request-to-operator',
        name: 'submittedRequestToOperator',
        exact: true,
        component: () =>
            import('./components/cloudtelephony/requestSubmittedToOperator.vue'),
        meta: {
            requiresAuth: false,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Cloud Telephony', label: 'Request Submitted To Operator' }],
        },
    },
    {
        path: '/D_I_D/Numbers/released-numbers',
        name: 'releasedNumbers',
        exact: true,
        component: () =>
            import('./components/cloudtelephony/releasedNumbers.vue'),
        meta: {
            requiresAuth: false,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Cloud Telephony', label: 'Released Numbers' }],
        },
    },
    {
        path: '/settings/DefaultTunes',
        name: 'listdefaulttunes',
        exact: true,
        component: () =>
            import('./components/settings/DefaultTunes.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: ' Default Tunes' }],
        },
    },
    {
        path: '/settings/TemplateDescription',
        name: 'listTemplateDescription',
        exact: true,
        component: () =>
            import('./components/settings/TemplateDescription.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: ' Template Descriptions' }],
        },
    },
    {
        path: '/admintabs/templateevent',
        name: 'listemplateevent',
        exact: true,
        component: () =>
            import('./components/admintabs/TemplateEvent.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Admin Tabs', label: ' Template Event' }],
        },
    },
    {
        path: '/admin/action/event/:eventId?',
        name: 'addEditEventTemplate',
        exact: true,
        component: () =>
            import('./components/admintabs/AddEditEventTemplate.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Admin', label: 'Event Templates' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/RCS-template-settings',
        name: 'RCS-template-settings',
        exact: true,
        component: () =>
            import('./components/admintabs/RCSTemplateSettings.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Admin', label: 'RCS Template Settings' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/critical-permissions',
        name: 'critical-permissions',
        exact: true,
        component: () =>
            import('./components/admintabs/CriticalPermissions.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Admin', label: 'Critical Permissions' }],
        },
    },
    {
        path: '/hosts',
        name: 'listhosts',
        exact: true,
        component: () =>
            import('./components/hostDetails.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Host', label: 'List Hosts' }],
        },
    },
    {
        path: '/hostdetails/:hostId?/:clientId?',
        name: 'hostDetails',
        exact: true,
        component: () =>
            import('./components/HostAllDetails.vue'),
        meta: {
            // requiresAuth: true,
            // checkModuleAccess: false,
            breadcrumb: [{ parent: 'Host', label: 'Host Details' }],
        },
    },
    {
        path: '/billing/hostAccStmt',
        name: 'HostAccountStatement',
        exact: true,
        component: () =>
            import('./components/HostAccountStatement.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Host', label: 'Billing' }],
        },
    },
    {
        path: '/visitors',
        name: 'listvisitors',
        exact: true,
        component: () =>
            import('./components/Visitors.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Visitors', label: 'Visitors List' }],
        },

    },
    {
        path: '/support-tickets/new-ticket',
        name: 'newTicktes',
        exact: true,
        component: () =>
            import('./components/supportTickets/newTickets.vue'),
        meta: {
            requiresAuth: false,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Support Ticktes', label: 'New Tickets' }],
        },
    },
    {
        path: '/support-tickets/open-ticket',
        name: 'openTicktes',
        exact: true,
        component: () =>
            import('./components/supportTickets/openTickets.vue'),
        meta: {
            requiresAuth: false,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Support Ticktes', label: 'Open Tickets' }],
        },
    },

    {
        path: '/support-tickets/closed-ticket',
        name: 'closedTicktes',
        exact: true,
        component: () =>
            import('./components/supportTickets/closedTickets.vue'),
        meta: {
            requiresAuth: false,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Support Ticktes', label: 'Closed Tickets' }],
        },
    },

    {
        path: '/settings/superAdminSMSAndEmailSetting',
        name: 'listSuperAdminTemplateActions',
        exact: true,
        component: () =>
            import('./components/settings/SuperAdminSMSAndEmailSetting.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Super Admin SMS And Email Settings' }],
        },
    },

    {
        path: '/settings/superAdminTemplateEvent',
        name: 'listSuperAdminTemplateEvent',
        exact: true,
        component: () =>
            import('./components/settings/SuperAdminTemplateEvent.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Super Admin Template Event' }],
        },
    }, 

    {
        path: '/settings/action/event/:eventId?',
        name: 'addEditSuperAdminEventTemplate',
        exact: true,
        component: () =>
            import('./components/settings/AddEditSuperAdminEventTemplate.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Super Admin Event Templates' }],
        },
    },

    {
        path: '/settings/companies',
        name: 'listofcompanies',
        exact: true,
        component: () =>
            import('./components/settings/Companies.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Companies' }],
        },
    },

    {
        path: '/settings/companiesSenderId',
        name: 'listofCompaniessenderid',
        exact: true,
        component: () =>
            import('./components/settings/CompaniesSenderId.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'SMS Sender Id' }],
        },
    },
    {
        path: '/admin/dashboard/:adminSubId?/web-bot-template-settings',
        name: 'web-bot-template-settings',
        exact: true,
        component: () =>
            import('./components/admintabs/WebBotTemplateSettings.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Admin', label: 'Web Bot Template Settings' }],
        },
    },
    {
        path: '/settings/adminSMSAndEmailSetting',
        name: 'listAdminTemplateActions',
        exact: true,
        component: () =>
            import('./components/settings/AdminSMSAndEmailSetting.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Admin SMS And Email Settings' }],
        },
    },
    {
        path: '/settings/adminTemplateEvent',
        name: 'listAdminTemplateEvent',
        exact: true,
        component: () =>
            import('./components/settings/AdminTemplateEvent.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Admin Template Event' }],
        },
    }, 
    {
        path: '/settings/action/adminevent/:eventId?',
        name: 'addEditAdminEventTemplate',
        exact: true,
        component: () =>
            import('./components/settings/addEditAdminEventTemplate.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Admin Event Templates' }],
        },
    },
    {
        path: '/settings/dashoboardlogos',
        name: 'listDashoboardLogos',
        exact: true,
        component: () =>
            import('./components/settings/DashoboardLogos.vue'),
        meta: {
            requiresAuth: true,
            checkModuleAccess: false,
            breadcrumb: [{ parent: 'Settings', label: 'Upload Logos' }],
        },
    }, 
    
];

const router = createRouter({
    history: createWebHistory(),
    routes
});
router.beforeEach((to, from, next) => {
    const authenticatedUser = localStorage.user_reference;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const checkModuleAccess = to.matched.some(record => record.meta.checkModuleAccess);
    const moduleId = to.meta.moduleId;
    const permission_fks = [1, 11, 12];

    if (requiresAuth && !authenticatedUser) {
        next('/login');
    }
    else {
        if (checkModuleAccess) {
            if (permission_fks.includes(moduleId)) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }

    }

});



export default router;