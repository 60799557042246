<template>
    <div class="layout-topbar p-shadow-4">
        <div class="layout-topbar-left">
            <a class="layout-topbar-logo" href="/#/login">
                <img id="logo" src='https://storage.googleapis.com/visitor-connect-dev/emailerImages/VisitorLogo-01-01-01-01-20240502104149-eACVvp74Zt20240502104149.jpg' alt="ultima-layout" style="height: 4rem" />
            </a>

            <a class="layout-menu-button p-shadow-6 p-ripple" @click="onMenuButtonClick($event)" v-ripple>
                <i class="pi pi-chevron-right"></i>
            </a>

            <a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
                <i class="pi pi-ellipsis-v fs-large"></i>
            </a>
        </div>

        <div class="layout-topbar-right" :class="{ 'layout-topbar-mobile-active': mobileTopbarActive }">
            <div class="layout-topbar-actions-left"></div>

            <div class="layout-topbar-actions-right">
                <ul class="layout-topbar-items p-mr-3">
                    <li class="layout-topbar-item notifications">
                    </li>
                    <li class="layout-topbar-item">
                        <a class="layout-topbar-action p-d-flex p-dir-row p-jc-left p-ai-left p-px-2" :style="{ 'min-width': '165px' }" @click="onTopbarItemClick($event, 'profile')" v-ripple>
                            <img v-if="user_image != null && user_image != ''" :src="user_image" style="width: 32px; height: 32px; border-radius: 50%" />
                            <img v-if="user_image == null || user_image == ''" src="/assets/layout/images/widgets/asset-profile-11.png" alt="avatar" style="width: 32px; height: 32px; border-radius: 50%" />

                            <span class="p-d-flex p-flex-column" :class="{ 'p-ml-2': !isRTL, 'p-mr-2': isRTL }">
                                <span class="p-text-bold p-text-capitalize overflow-span">{{ user_full_name }}</span>
                                <small>{{ my_did }}</small>
                            </span>
                        </a>

                        <transition name="layout-topbar-action-panel" :style="{ 'min-width': '15rem' }">
                            <ul class="layout-topbar-action-panel p-shadow-6" v-show="activeTopbarItem === 'profile'">
                                <li class="layout-topbar-action-item">
                                    <a class="p-d-flex p-flex-row p-ai-center p-ripple" v-ripple @click="userLogout">
                                        <i class="pi pi-power-off" :class="{ 'p-mr-2': !isRTL, 'p-ml-2': isRTL }"></i>
                                        <span>Logouts</span>
                                    </a>
                                </li>
                            </ul>
                        </transition>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from './service/ApiService';

export default {
  emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'search-click', 'search-toggle'],
  data() {
    return {
      my_did: '',
      user_full_name: '',
      user_image: '',
      searchText: '',
      items: [
        {
          label: 'UI KIT',
          items: [
            [
              {
                label: 'UI KIT 1',
                items: [
                  {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout'},
                  {label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input'},
                  {label: 'Float Label', icon: 'pi pi-fw pi-bookmark', to: '/floatlabel'},
                  {label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button'},
                  {label: 'File', icon: 'pi pi-fw pi-file', to: '/file'},
                ],
              },
            ],
            [
              {
                label: 'UI KIT 2',
                items: [
                  {label: 'Table', icon: 'pi pi-fw pi-table', to: '/table'},
                  {label: 'List', icon: 'pi pi-fw pi-list', to: '/list'},
                  {label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree'},
                  {label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel'},
                  {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart'},
                ],
              },
            ],
            [
              {
                label: 'UI KIT 3',
                items: [
                  {label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay'},
                  {label: 'Media', icon: 'pi pi-fw pi-image', to: '/media'},
                  {label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menus'},
                  {label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages'},
                  {label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc'},
                ],
              },
            ],
          ],
        },
        {
          label: 'UTILITIES',
          items: [
            [
              {
                label: 'UTILITIES 1',
                items: [
                  {label: 'Display', icon: 'pi pi-fw pi-desktop', to: '/display'},
                  {label: 'Elevation', icon: 'pi pi-fw pi-external-link', to: '/elevation'},
                ],
              },
              {
                label: 'UTILITIES 2',
                items: [{label: 'FlexBox', icon: 'pi pi-fw pi-directions', to: '/flexbox'}],
              },
            ],
            [
              {
                label: 'UTILITIES 3',
                items: [{label: 'Icons', icon: 'pi pi-fw pi-search', to: '/icons'}],
              },
              {
                label: 'UTILITIES 4',
                items: [
                  {label: 'Text', icon: 'pi pi-fw pi-pencil', to: '/text'},
                  {label: 'Widgets', icon: 'pi pi-fw pi-star-o', to: '/widgets'},
                ],
              },
            ],
            [
              {
                label: 'UTILITIES 5',
                items: [
                  {label: 'Grid System', icon: 'pi pi-fw pi-th-large', to: '/grid'},
                  {label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', to: '/spacing'},
                  {label: 'Typography', icon: 'pi pi-fw pi-align-center', to: '/typography'},
                ],
              },
            ],
          ],
        },
      ],
    };
  },
  ApiService: null,
  created() {
  this.ApiService = new ApiService();
  },
  mounted() {
    if (localStorage.user) {
      const localdata = JSON.parse(localStorage.user);
      this.user_full_name = localdata.user_full_name;
      this.user_image = localdata.user_image;
    }
  },
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
    topbarMenuActive: {
      type: Boolean,
      default: false,
    },
    activeTopbarItem: String,
    mobileTopbarActive: Boolean,
    searchActive: Boolean,
  },
  methods: {
    onSearchContainerClick(event) {
      this.$emit('search-click', event);
    },
    changeSearchActive(event) {
      this.$emit('search-toggle', event);
    },
    onMenuButtonClick(event) {
      this.$emit('menubutton-click', event);
    },
    onTopbarMenuButtonClick(event) {
      this.$emit('topbar-menubutton-click', event);
    },
    onTopbarItemClick(event, item) {
      if (item === 'search') {
        this.$emit('search-toggle', event);
      }

      this.$emit('topbaritem-click', {originalEvent: event, item: item});
    },
    onTopbarMobileButtonClick(event) {
      this.$emit('topbar-mobileactive', event);
    },
    onRightPanelButtonClick(event) {
      this.$emit('rightpanel-button-click', event);
    },
    onSearchKeydown(event) {
      if (event.keyCode == 13) {
        this.$emit('search-toggle', event);
      }
    },
    userLogout() {
      this.ApiService.userlogout().then((items) => {
        if (items.status === 200) {
          const successMsg = items.message;
          this.$toast.add({severity: 'success', summary: 'Successful', detail: successMsg, life: 3000});
        }
      });
    },
  },
  computed: {
    topbarItemsClass() {
      return [
        'topbar-items animated fadeInDown',
        {
          'topbar-items-visible': this.topbarMenuActive,
        },
      ];
    },
    isRTL() {
      return this.$appState.RTL;
    },
  },
};
</script>
