var CommonMixins = {
    methods: {
          genrateSrNo(i) {
            var sr = i + 1;
            return (30 * this.page_no) + sr;
        },
        formatCurrency(value) {
            let rupeeIndian = Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
            });
            return rupeeIndian.format(value)
        }
    }
};
export default CommonMixins;