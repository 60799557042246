import { reactive } from "vue";
const state = reactive({
   templateReplyTypeList: [
      { label: 'Stop Chatbot', value: 0 },
      { label: 'Text', value: 1 },
      { label: 'Button', value: 2 },
      { label: 'Number Type', value: 3 },
      { label: 'Aadhar number', value: 4 },
      { label: 'OTP', value: 5 },
      { label: 'Voter Card Number', value: 6 },
      { label: 'Image', value: 7 },
      { label: 'Contact', value: 8 },
      { label: 'Location', value: 9 },
      { label: 'Video', value: 10 },
      { label: 'Mobile Number', value: 11 },
      { label: 'Dropdown', value: 12 },
      { label: 'Date', value: 13 },
      { label: 'Passport Number', value: 14 },
      { label: 'Radio Button', value: 15 },
      { label: 'QR Code', value: 16 },
   ],
   ChannelIdList: [
      { label: 'WhatsApp', value: 1 },
      { label: 'RCS', value: 2 },
      { label: 'Sms', value: 3 },
      { label: 'Email', value: 4 },
   ],
   headerFileTypeList: [
      { label: 'Image', value: 1 },
      { label: 'Document', value: 2 },
      { label: 'Video', value: 3 },
      { label: 'Audio', value: 4 },
   ],
   actionTakingList: [
      { value: 1, label: 'Action Taking User' },
      { value: 2, label: 'Admin User' },
      { value: 3, label: 'Support User' },
      { value: 4, label: 'Voter' },
      { value: 5, label: 'Current User' }
   ],
   documentTypes: [
      { value: 1, label: "Appointment letter of admin" },
      { value: 2, label: "Appointment letter of owner" },
      { value: 3, label: "Appointment letter of co-ordinator" },
      { value: 4, label: "Purchase Order" },
      { value: 5, label: "Technical Information" },
      { value: 6, label: "Other" },
   ],
   actionTakingListForAdmin: [
      { value: 1, label: 'Owner' },
      { value: 2, label: 'Admin' },
      { value: 3, label: 'Co-ordinator' },
      { value: 4, label: 'Billing' }
   ],

   //cloud telephony virtual number status, operator list
   statusList: [
      { value: "0", label: "Free" },
      { value: "1", label: "Active" },
      { value: "2", label: "Under admin notice period" },
      { value: "3", label: "request submited to operator" },
      { value: "4", label: "Release Number" },
   ],
   operatorList: [
      { value: "1", label: "Vodafone" },
      { value: "2", label: "Idea" },
      { value: "3", label: "Airtel" },
      { value: "4", label: "Not Known" },
      { value: "5", label: "BSNL" },
      { value: "6", label: "Jio" },
      { value: "7", label: "TATA" },
      { value: "8", label: "Reliance" },
   ],
});

export default { state };