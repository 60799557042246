<template>
	<div class="layout-footer p-d-flex p-ai-center p-p-4 p-shadow-2">
        <img id="footer-logo" :src="'https://storage.googleapis.com/visitor-connect-dev/emailerImages/270X37.8-0120240418160503_Gkl9yHv7Ya20240418160503.png'" alt="footer-logo" style="height: 2.1rem">
    </div>
</template>

<script>
export default {
  name: 'AppFooter',
  props: {
    layoutMode: {
      type: String,
      default: 'light',
    },
  },
  computed: {
    isRTL() {
      return this.$appState.RTL;
    },
  },
};
</script>

<style scoped>

</style>
