<template>
	<div class="layout-breadcrumb-container p-d-flex p-jc-between p-ai-center p-shadow-1">
		<Breadcrumb :home="home" :model="items" class="layout-breadcrumb p-pl-4 p-py-2"></Breadcrumb>
	</div>
</template>

<script>
export default {
  data() {
    return {
      home: {icon: 'pi pi-home', to: '/dashboard'},
      items: [],
    };
  },
  watch: {
    $route() {
      this.watchRouter();
    },
  },
  created() {
    this.watchRouter();
  },
  methods: {
    watchRouter() {
      if (this.$router.currentRoute.value.meta.breadcrumb) {
        this.items = [];
        const x = this.$router.currentRoute.value.meta.breadcrumb[0];
        for (const pro in x) {
          this.items.push({label: x[pro], to: []},
          );
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
